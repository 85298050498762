import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  className?: string
}

export const Separator = memo(function Separator({ className }: Props) {
  return <Container className={className} />
})

const Container = styled.div`
  width: 0.125rem;
  height: 6.7vh;
  background: ${({ theme }) => theme.colors.variants.primaryDark2};
  max-height: 3.75rem;
  margin: 0 auto 1.25rem;

  @media (max-width: 767px) {
    height: 5vh;
  }
`
